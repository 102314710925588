import React from 'react'
import { Table } from 'react-bootstrap'
import { useSelector } from 'react-redux'
import nextId from 'react-id-generator'

const RowNode = ({ row }) => (
  Object.keys(row).map(key => (<td key={key}>{row[key]}</td>))
)

const TableViewer = () => {
  const data = useSelector(state => state.didyme.data)

  if (!data || !data.length) {
    return 'No Data'
  }

  const HeadMapper = () => Object.keys(data[0]).map(key => (
    <th key={key}>{key}</th>
  ))

  const RowMapper = () => data.map((row, index) => (
    <tr key={nextId()}>
      <td>{index}</td>
      <RowNode row={row} />
    </tr>
  ))

  return (
    <Table striped bordered hover>
      <thead>
        <tr>
          <th>#</th>
          <HeadMapper />
        </tr>
      </thead>
      <tbody>
        <RowMapper />
      </tbody>
    </Table>
  )
}

export default TableViewer
