import React, { useEffect, useState, useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Button } from 'react-bootstrap'

import TopBar from 'components/TopBar'
import ReactJson from 'react-json-view'

import { Hint } from 'react-autocomplete-hint'

import {
  changeFrom,
  changeTo,
  checkPath,
  fetch,
  PATH,
  path,
  pathV2,
} from './reducers/didyme'
import TableViewer from './components/TableViewer'

const gaiaTypes = ['ressourceAbstraites', 'ressourceAbstraite', 'objetPersistant', 'sousSaf',
  'signalJointDeZone', 'parcoursTopologiqueIVP', 'personnalisationSectionnement',
  'competenceSurSaf', 'contrainteAppareilVoie', 'typeHabillage',
  'constanteResponse', 'hauteurBoudinMax', 'brancheVS', 'signalFinExploitation',
  'typeNatureOT', 'noeud', 'groupementSections', 'proprietaire',
  'plaqueDirection', 'typeSupportSignal', 'typeAnnonce', 'typeSurveillance',
  'pointOperationnel', 'systemeReperageGare',
  'associationCategorieTarifaireGare', 'noeudSegmentGestion', 'itineraire',
  'distanceMinEssieuxLgv', 'projectionPk', 'signalArretAnnonce',
  'structureLocalisationSrPk', 'consigne', 'anomalieVoiesLigne', 'inclinaison',
  'version', 'segmentTarifairePRResponse', 'habillageValeur', 'moduleSuivi',
  'entreprise', 'distanceEssieuxLgv', 'typeStructure', 'habillageEntreprise',
  'ressourceInfrastructure', 'macaronItineraire', 'signalManoeuvre',
  'systemeReperageAdministratif', 'tronconDItineraireLigne',
  'modeGestionItineraire', 'typeModeFonctionnementPoste', 'heurtoir',
  'signalDeProtection', 'classificationVs', 'etatSignal', 'milliMetre',
  'distanceEssieux', 'chaineCaractere', 'habillageTypeValeur',
  'ressourceParcoursInfrastructure', 'ressourceInfrastructureAgregeante',
  'detailParcoursIVP', 'segmentation', 'sel', 'localisateur', 'arianeError',
  'distanceNominaleN', 'structureGeographique', 'localisationPkNiveau',
  'segmentGestion', 'typeDetection2', 'typeDetection1', 'typeDetection3',
  'voieDeService', 'associationNoeudAgrege', 'validitePk',
  'associationRessourceInstallationFixe', 'pkExecution', 'localisationPri',
  'natureValeur', 'noeudDeLigne', 'localisationQuai',
  'typeInstallationFixeExtremite', 'localisationPkTunnel', 'distanceNominaleM',
  'reponseServiceItineraireParcoursTopologique', 'epaisseurMinBoudin',
  'systemeReperagePk', 'brancheAPV',
  'relationRelationParcoursRessourceInfrastructure', 'segmentSagitaire',
  'longueurMaxNezVehicule', 'ressourceInstallationFixe',
  'infoComplementaireVoie', 'entier', 'associationInstallationFixeCommandee',
  'segment', 'typeClassementOT', 'contrainteVSOrigine', 'categorieTarifaireGare',
  'signal', 'typeAppareilDeVoie', 'proprietaireQuaiResponse', 'baliseProximite',
  'typeElementSousStation', 'zep', 'chargeEssieu', 'elementParcours',
  'typeSigneConventionnel', 'ressourceInfrastructurePonctuelle',
  'hauteurMinimaleBoudin', 'ampere', 'typeNoeudDeLigne', 'longueurSR',
  'elementParcoursIVP', 'signeConventionnel', 'distanceMinEssieuxClassique',
  'relationParcours', 'detailParcours', 'decimal', 'region', 'gare',
  'nomLocalDeVoie', 'categorieTarifaire', 'commune', 'hauteurMaxBoudin',
  'competenceTerritorial', 'diametreRoue', 'ressourceConnexion',
  'associationNoeudAgregeant', 'typeEtat', 'verificationCE', 'relationSRVoie',
  'relationExtremite', 'parcoursTopologique', 'mouvementPossible',
  'ressourceInfrastructureResteDuMonde', 'tonne', 'collectionLocalisationPk',
  'appareilDeVoie', 'anomaliePk', 'localisationPkOuvrageTerre',
  'systemeReperageLigne', 'typePointRuptureTrafic', 'associationSegmentation',
  'catSecuIncendie', 'courantMaxPantoDeuxfc', 'relationBrancheAPV',
  'extremiteCDV', 'renseignementTechnique', 'signalDivers', 'pedale',
  'typePlaqueCantonSignal', 'tunnel', 'topologieDate',
  'ressourceInfrastructurePorteuse', 'largeurMinJante', 'departement',
  'troncCommun', 'diametreMinRoue', 'feeder', 'passageANiveau',
  'chargeMinEssieu', 'ressourceInfrastructureOrientee',
  'distanceNominaleMetrique', 'inclinaisonRail', 'contrainteItineraire',
  'extremiteVoie', 'elementCatenaire', 'verificationCEResponse',
  'informationLigne', 'abstractSaf', 'hauteurBoudinMin', 'typeSaf',
  'extremiteReseau', 'impedanceMaxRoue', 'metre', 'relationLigne',
  'parcoursLigne', 'relationTIVItineraire', 'distanceNominaleNormale',
  'parcoursTopologiques', 'sectionnementElementaire', 'typeDeVoie',
  'sectionElementaire', 'assocSegmentationTarifaire', 'equivalenceRT',
  'ficheComplementaireDetournement', 'conversionPk', 'localisationGare',
  'largeurJante', 'localisationSurSr', 'localisationPk', 'typeLigne',
  'associationInstallationFixeDeleguee', 'portion', 'typeElectrification',
  'modeCantonnement', 'typeGare', 'relationLocalisationJDZ',
  'centralSousStation', 'competenceFonctionnelle', 'detournement',
  'relationLocalisationExtremite', 'installationFixeVirtuelle',
  'tronconDItineraireVoie', 'noeudDeVoie', 'anomalieVoie', 'traverseVoiePietons',
  'ressourceInfrastructureLineaire', 'ressourceConnexionOrientee', 'versionGSMR',
  'conformiteIE', 'segmentDebitLigne', 'parcoursOperationnel',
  'collectionLocalisationSurSr', 'acces', 'installationFixeProtection',
  'pointRuptureTrafic', 'regionRFF', 'libelleTIL', 'typeSystDetectTrain1',
  'installationFixeLocalisee', 'longueurNezVehicule', 'typeSystDetectTrain3',
  'typeSystDetectTrain2', 'habillageOriente', 'habillageStructureAdministrative',
  'typeAngleOT', 'quai', 'groupementDeZEP', 'validiteSel',
  'assocSegmentationTarifairePR', 'contrainteEtatSignalAutre', 'validiteZep',
  'circuitDeVoie', 'contrainteEtatSignalOrigine', 'jointDeZone',
  'localisationSR', 'materiauBandeFrt1', 'typeNoeudDeVoie', 'courantPantoUnfc',
  'materiauBandeFrt2', 'reponseServiceListeItineraireSousGrapheTIV',
  'associationHabillage', 'ouvrageTerre', 'signalLimitationDeVitesse',
  'courantMaxPantoUnfc', 'typePlaqueIdentificationSignal', 'referentiel',
  'constante', 'materiau1', 'relationJointDeZone', 'signalTVM', 'materiau2',
  'competenceSurVoie', 'poste', 'communication', 'distanceEssieuxClassique',
  'incertitude', 'voie', 'typeImplantation', 'regionSNCF',
  'typeProtectionSignal', 'associationRessourceAgregeante', 'portionOrientee',
  'postesEncadrant', 'conformiteIEResponse', 'detecteurBoiteChaude',
  'esclavageSignal', 'ohm', 'structureSrPkLigne', 'proprietaireQuai',
  'diametreRoueTr', 'pointRemarquable', 'categorieTarifaireSegment',
  'rattachementStructure', 'unite', 'specialite', 'fenetreSuivi', 'ensemble',
  'competenceSurLigne', 'relationSRLigne', 'contrainteVSDestination',
  'instanceInfrastructure', 'prIdentPK', 'habillageRegionSNCF',
  'distanceMaxEssieux', 'saf', 'mnemoCodeImmuable', 'typeTraverseVoie',
  'typeHauteur', 'typeSousReseauSegment', 'courantPantoDeuxfc',
  'referenceLocalisation', 'ligne', 'cantonEmprunte', 'valeur', 'esclavageDEtat',
  'localisation', 'epaisseurBoudin', 'extremiteLigne', 'classificationRteVs',
  'rupturePk', 'diametreMinRoueTr', 'typeCouverture',
  'associationRessourceAgrege', 'impedanceRoue', 'segmentTarifaire', 'structure',
  'sousSecteur', 'secteur', 'typeRevetement', 'systemeReperage',
  'signalTractionElectrique', 'groupementCatenaire', 'systemeReperageVoie',
  'pointRemarquablesEncadrant', 'canton', 'pk', 'sousStation',
  'segmentStrategique', 'typeAVDate', 'typeInstallationFixe']

const Home = () => {
  const dispatch = useDispatch()
  const pathD = useSelector(state => state.didyme.path)
  const { from, to } = useSelector(state => state.didyme)

  useEffect(() => {
    dispatch(fetch())
  }, [])

  const InputComment = () => {
    const [comment, setComment] = useState('')
    const onChangeComment = useCallback(
      e => {
        setComment(e.target.value)
      },
      [comment],
    )
    const handleApprove = approved => () => {
      dispatch(checkPath(pathD, from, to, approved, comment))
      setComment('')
    }
    return (
      <>
        <input
          type="text"
          onChange={onChangeComment}
          value={comment}
        />
        <p>
          <Button variant="success" onClick={handleApprove(true)} disabled={comment === ''}>Approve</Button>
          <Button variant="danger" onClick={handleApprove(false)} disabled={comment === ''}>Reject</Button>
        </p>
      </>
    )
  }

  function handleChangeFrom(e) {
    dispatch(changeFrom(e.target.value))
  }

  function handleChangeTo(e) {
    dispatch(changeTo(e.target.value))
  }

  function goPath() {
    dispatch(path(from, to))
  }

  function goPathV2() {
    dispatch(pathV2(from, to))
  }

  function goFetch() {
    dispatch(fetch(pathD))
  }

  function onEdit(edit) {
    dispatch({
      type: PATH,
      path: edit,
    })
  }

  return (
    <div className="ml-3">
      <TopBar appName="Gaia'Viz" />
      <br />
      <br />
      <br />
      <br />
      <br />
      <ReactJson
        src={pathD}
        indentWidth={2}
        collapsed={false}
        name="path"
        onEdit={edit => onEdit(edit.updated_src)}
        onDelete={edit => onEdit(edit.updated_src)}
        onAdd={edit => onEdit(edit.updated_src)}
      />
      <br />
      <p>
        From:
        <Hint options={gaiaTypes}>
          <input onChange={handleChangeFrom} value={from} name="from" />
        </Hint>
      </p>
      <p>
        To:
        <Hint options={gaiaTypes}>
          <input type="text" onChange={handleChangeTo} value={to} name="to" />
        </Hint>
      </p>
      <button onClick={goPath} type="button">Path</button>
      <button onClick={goPathV2} type="button">Path V2</button>
      {Boolean(pathD && Object.keys(pathD).length) && (
        <>
          <button onClick={goFetch} type="button">Fetch</button>
          <br />
          <p>
            Comment:
            <br />
            <InputComment />
          </p>
          <br />
        </>
      )}
      <br />
      <TableViewer />
    </div>
  )
}

export default Home
