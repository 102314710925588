import produce from 'immer'
import { post, get } from '@osrdata/app_core/dist/requests'

export const FETCH = 'FETCH'
export const PATH = 'PATH'
export const CHANGE_FROM = 'CHANGE_FROM'
export const CHANGE_TO = 'CHANGE_TO'
export const APPROVE_PATH = 'APPROVE_PATH'

const API_URLS = {
  fetch: '/didyme/fetch/',
  path: '/didyme/paths/gaia/',
  checkpath: '/didyme/checkpath/',
}

const initialState = {
  data: [],
  path: {},
  from: '',
  to: '',
}

export default function reducer(state = initialState, action) {
  return produce(state, draft => {
    switch (action.type) {
      case FETCH:
        draft.data = action.data
        break
      case PATH:
        draft.path = action.path
        break
      case CHANGE_FROM:
        draft.from = action.from
        break
      case CHANGE_TO:
        draft.to = action.to
        break
      default:
    }
  })
}

export const fetch = payload => async dispatch => {
  const data = await post(API_URLS.fetch, payload)
  dispatch({
    type: FETCH,
    data,
  })
  return data
}

export const path = (from, to) => async dispatch => {
  const pathT = await get(`${API_URLS.path}${from}/gaia/${to}/`)
  dispatch({
    type: PATH,
    path: pathT,
  })
  return path
}

export const pathV2 = (from, to) => async dispatch => {
  const pathT = await get(`${API_URLS.path}${from}/gaia/${to}/`, {
    model: 'v2',
  })
  dispatch({
    type: PATH,
    path: pathT,
  })
  return path
}

export const changeFrom = value => ({
  type: CHANGE_FROM,
  from: value,
})

export const changeTo = value => ({
  type: CHANGE_TO,
  to: value,
})

export const checkPath = (pathD, start, end, approved, comment) => async dispatch => {
  const data = await post(API_URLS.checkpath, {
    path: pathD, path_start: start, path_end: end, approved, comment,
  })
  dispatch({
    type: APPROVE_PATH,
    data,
  })
}
